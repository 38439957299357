<template>
  <div>
    <div class="container">
      <InfoBox
        variant="success"
        size="small"
      >
        Už len overenie, že ste to vy a špeciálna cena poistenia so zľavou je vaša.
      </InfoBox>
      <InfoBox
        v-if="hasError"
        variant="danger"
      >
        Ponuku sa nepodarilo načítať, skontrolujte zadanú hodnotu
      </InfoBox>
      <FormGroup
        label="Zadajte, prosím posledné 4 resp. 3 znaky z vášho rodného čísla za lomkou"
        :rules="['required']"
      >
        <InputText
          v-model="prefilledOffer.key"
        />
      </FormGroup>
      <BaseSpinner
        v-if="isLoading"
        variant="primary"
        :size="32"
        center
      />
    </div>
  </div>
</template>

<script>
import InfoBox from '@gds/components/InfoBox';
import FormGroup from '@gds/components/FormGroup';
import InputText from '@gds/components/InputText';
import BaseSpinner from '@gds/components/BaseSpinner';

import { mapState } from 'vuex';

export default {
  name: 'AuthorizePrefilledOffer',
  components: {
    InfoBox,
    FormGroup,
    InputText,
    BaseSpinner,
  },
  data() {
    return {
      isLoading: false,
      hasError: false,
    };
  },
  computed: {
    ...mapState({
      prefilledOffer: (store) => store.prefilledOffer,
    }),
  },
  methods: {
    async tryProceed() {
      if (!this.isLoading && this.$v.validator.check()) {
        this.isLoading = true;
        this.hasError = false;
        const result = await this.$store.dispatch('loadPrefilledOffer', this.$apollo);
        if (result) {
          this.$router.push('/1');
        } else {
          this.hasError = true;
        }
        this.isLoading = false;
      }
    },
  },
};
</script>
